import React from 'react';
import Stage from './stage';
import Layout from '../../common/layout';
import { Typography } from '@mui/material';
import styled from 'styled-components';
import { Box } from '@mui/material';

export interface CompanyInfo {
    name: string;
    address: {
        street: string;
        postalCode: string;
        city: string;
        country: string;
    };
    contact: {
        phone: string;
        email: string;
    };
    vatId: string;
}

export interface LegalAuthority {
    name: string;
    link?: string;
}

export interface ProfessionalAssociation {
    name: string;
}

export const Content = styled.div`
  padding: 3.125rem 0;
  grid-column: 3 / span 20;
  max-width: 800px;
  margin: 0 auto;
`;

export const Section = styled(Box)`
  margin: 1.5rem 0;
`;

export const ContactInfo = styled(Typography)`
  margin: 1rem 0;
  line-height: 1.6;
`;

export const LegalLink = styled.a`
  color: inherit;
  text-decoration: none;
  border-bottom: 1px solid currentColor;
  
  &:hover {
    opacity: 0.8;
  }
`;

export const AssociationTitle = styled(Typography)`
  font-weight: bold;
  margin: 0.5rem 0;
`;

const companyInfo: CompanyInfo = {
    name: 'Alexander Josef Krempler',
    address: {
        street: 'Fürbergstraße 27',
        postalCode: '5020',
        city: 'Salzburg',
        country: 'Österreich',
    },
    contact: {
        phone: '+43 (0) 664 5490464',
        email: 'krempler.alexander@gmail.com',
    },
    vatId: 'ATU76720201',
};

const legalAuthorities: LegalAuthority[] = [
    {
        name: 'Gewerbeordnung',
        link: 'https://www.ris.bka.gv.at/GeltendeFassung.wxe?Abfrage=Bundesnormen&Gesetzesnummer=10007517',
    },
    {
        name: 'Bezirkshauptmannschaft Salzburg-Umgebung',
    },
];

const professionalAssociations: ProfessionalAssociation[] = [
    { name: 'FG Gewerbliche Dienstleister' },
    { name: 'FG Entsorgungs- und Ressourcenmanagement' },
    { name: 'LG Baustoff-, Eisen- und Holzhandel' },
];

const Impressum: React.FC = () => {
    return (
        <>
            <Stage />
            <Layout>
                <Content>
                    <Typography variant="h1" gutterBottom>
                        Impressum
                    </Typography>

                    <Section>
                        <ContactInfo>
                            Angaben gem. § 5 TMG:
                            <br />
                            {companyInfo.name}
                            <br />
                            {companyInfo.address.street} {companyInfo.address.postalCode}
                            <br />
                            {companyInfo.address.city} {companyInfo.address.country}
                        </ContactInfo>

                        <ContactInfo>
                            Kontaktaufnahme:
                            <br />
                            Telefon: {companyInfo.contact.phone}
                            <br />
                            E-Mail: {companyInfo.contact.email}
                        </ContactInfo>

                        <ContactInfo>
                            Umsatzsteuer-Identifikationsnummer gem. § 27 a Umsatzsteuergesetz:{' '}
                            {companyInfo.vatId}
                        </ContactInfo>
                    </Section>

                    <Section>
                        <Typography variant="h6" gutterBottom>
                            Berufsrecht:
                        </Typography>
                        {legalAuthorities.map((authority, index) => (
                            <div key={index}>
                                {authority.link ? (
                                    <LegalLink href={authority.link} target="_blank" rel="noopener noreferrer">
                                        {authority.name}
                                    </LegalLink>
                                ) : (
                                    <Typography>{authority.name}</Typography>
                                )}
                            </div>
                        ))}
                    </Section>

                    <Section>
                        {professionalAssociations.map((association, index) => (
                            <AssociationTitle key={index} variant="body1">
                                {association.name}
                            </AssociationTitle>
                        ))}
                    </Section>
                </Content>
            </Layout>
        </>
    );
};

export default Impressum;