import React from 'react';
import {
    Typography,
    Box,
    List,
    ListItem,
    ListItemText,
    Link,
    Divider,
    styled
} from '@mui/material';
import Stage from "./stage";
import {Layout} from "../../common";
import {Content} from "./impressum";


const Section = styled(Box)(({ theme }) => ({
    marginBottom: theme.spacing(4),
}));

const DataSecurity: React.FC = () => {
    const sections = [
        {
            title: 'Datenschutzerklärung',
            content: `In folgender Datenschutzerklärung informieren wir Sie über die wichtigsten Aspekte der Datenverarbeitung im Rahmen unserer
      Webseite. Wir erheben und verarbeiten personenbezogene Daten nur auf Grundlage der gesetzlichen Bestimmungen
      (Datenschutzgrundverordnung, Telekommunikationsgesetz 2003).`
        },
        {
            title: 'IP-Adresse und Sitzungsdaten',
            content: `Sobald Sie als Benutzer auf unsere Webseite zugreifen oder diese besuchen wird Ihre IP-Adresse, Beginn sowie Beginn und Ende
      der Sitzung erfasst. Dies ist technisch bedingt und stellt somit ein berechtigtes Interesse iSv Art 6 Abs 1 lit f DSGVO.`
        },
        {
            title: 'Kontakt mit uns',
            content: `Wenn Sie uns, entweder über unser Kontaktformular auf unserer Webseite, oder per Email kontaktieren, dann werden die von Ihnen
      an uns übermittelten Daten zwecks Bearbeitung Ihrer Anfrage oder für den Fall von weiteren Anschlussfragen für sechs Monate
      bei uns gespeichert. Es erfolgt, ohne Ihre Einwilligung, keine Weitergabe Ihrer übermittelten Daten.`
        },
        {
            title: 'Cookies',
            content: `Unsere Website verwendet so genannte Cookies. Dabei handelt es sich um kleine Textdateien, die mit Hilfe des Browsers auf
      Ihrem Endgerät abgelegt werden. Sie richten keinen Schaden an. Wir nutzen Cookies dazu, unser Angebot nutzerfreundlich zu
      gestalten. Einige Cookies bleiben auf Ihrem Endgerät gespeichert, bis Sie diese löschen. Sie ermöglichen es uns, Ihren Browser
      beim nächsten Besuch wiederzuerkennen. Wenn Sie dies nicht wünschen, so können Sie Ihren Browser so einrichten, dass er Sie
      über das Setzen von Cookies informiert und Sie dies nur im Einzelfall erlauben. Bei der Deaktivierung von Cookies kann die
      Funktionalität unserer Website eingeschränkt sein.`
        },
        {
            title: 'Google Maps',
            content: `Unsere Website verwendet Funktionen des Webkartendienstes „Google Maps". Der Dienstanbieter dieser Funktion ist:

      Google Ireland Limited
      Gordon House, Barrow Street
      Dublin 4. Ireland
      Tel: +353 1 543 1000

      Im Zuge der Nutzung von Google Maps ist es notwendig Ihre IP-Adresse zu speichern und zu verarbeiten. Die Datenverarbeitung erfolgt auf Basis der gesetzlichen Bestimmungen des § 96 Abs 3 TKG sowie des Art 6 Abs 1 lit f (berechtigtes Interesse) der DSGVO.`,
            links: [
                {
                    text: 'Google Datenschutzerklärung',
                    url: 'https://policies.google.com/privacy?hl=de'
                },
                {
                    text: 'EU-US Privacy Shield Framework',
                    url: 'https://www.privacyshield.gov/EU-US-Framework'
                }
            ]
        },
        {
            title: 'Google Fonts',
            content: `Unsere Website verwendet Schriftarten von „Google Fonts". Der Dienstanbieter dieser Funktion ist:

      Google Ireland Limited
      Gordon House, Barrow Street
      Dublin 4. Ireland
      Tel: +353 1 543 1000

      Beim Aufrufen dieser Webseite lädt Ihr Browser Schriftarten und speichert diese in den Cache. Die Nutzung von „Google-Fonts" dient der Optimierung unserer Dienstleistung und der einheitlichen Darstellung von Inhalten. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar.`,
            links: [
                {
                    text: 'Google Fonts FAQ',
                    url: 'https://developers.google.com/fonts/faq'
                }
            ]
        },
        {
            title: 'EmailJS',
            content: `Für die Verarbeitung und den Versand von E-Mails nutzen wir den Dienst EmailJS. EmailJS verarbeitet die von Ihnen eingegebenen
      Kontaktdaten, um die E-Mail-Kommunikation zu ermöglichen.`,
            links: [
                {
                    text: 'EmailJS Datenschutzerklärung',
                    url: 'https://www.emailjs.com/legal/privacy-policy/'
                }
            ]
        },
        {
            title: 'Ihre Rechte als Betroffener',
            content: 'Sie als Betroffener haben bezüglich Ihrer Daten, welche bei uns gespeichert sind grundsätzlich ein Recht auf:',
            bulletPoints: [
                'Auskunft',
                'Löschung der Daten',
                'Berichtigung der Daten',
                'Übertragbarkeit der Daten',
                'Wiederruf und Widerspruch zur Datenverarbeitung',
                'Einschränkung'
            ]
        }
    ];

    return (
        <>
            <Stage />
            <Layout>
            <Content>
                    <Typography variant="h3" component="h1" gutterBottom>
                        Erklärung zur Informationspflicht
                    </Typography>

                    {sections.map((section, index) => (
                        <Section key={index}>
                            <Typography variant="h5" component="h2" gutterBottom>
                                {section.title}
                            </Typography>

                            {section.content && (
                                <Typography variant="body1" paragraph style={{ whiteSpace: 'pre-line' }}>
                                    {section.content}
                                </Typography>
                            )}

                            {section.bulletPoints && (
                                <List>
                                    {section.bulletPoints.map((point, i) => (
                                        <ListItem key={i}>
                                            <ListItemText primary={point} />
                                        </ListItem>
                                    ))}
                                </List>
                            )}

                            {section.links && (
                                <Box mt={2}>
                                    {section.links.map((link, i) => (
                                        <Box key={i} mb={1}>
                                            <Link
                                                href={link.url}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                {link.text}
                                            </Link>
                                        </Box>
                                    ))}
                                </Box>
                            )}

                            {index < sections.length - 1 && <Divider sx={{ my: 4 }} />}
                        </Section>
                    ))}

                <Section>
                    <Typography variant="h5" component="h2" gutterBottom>
                        Bildmaterial</Typography>

                    <Typography variant="body1">
                        Die auf unserer Website verwendeten Bilder stammen von{' '}
                        <Link href='https://unsplash.com/' target='_blank' rel='noopener'>
                            Unsplash.com
                        </Link>
                        . Diese Bilder sind lizenzfrei und können ohne Quellenangabe
                        verwendet werden.
                    </Typography>
                        <Typography variant="body1">

                        <Link href={'https://unsplash.com/photos/text-HI30m3l6S3o'}>
                            Impressum Banner
                        </Link>
                    </Typography>
                </Section>


                    <Section>
                        <Typography variant="h5" component="h2" gutterBottom>
                            Kontakt
                        </Typography>
                        <Typography variant="body1">
                            Webseitenbetreiber: Alexander Krempler
                        </Typography>
                        <Typography variant="body1">
                            Telefonnummer: +43 (0) 664 5490464
                        </Typography>
                        <Typography variant="body1">
                            Email: krempler.alexander@gmail.com
                        </Typography>
                    </Section>
            </Content>
            </Layout>
        </>
    );
};

export default DataSecurity;